import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"

export const SearchStateContext = React.createContext()

const Typesense = require("typesense")

const SearchContextProvider = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState("")
  const [results, setResults] = useState([])
  const [loading, setLoading] = useState(false)

  // if url has search term, set search term to url search term
  useEffect(() => {
    if (window.location.href.indexOf("/?search=") > -1) {
      const urlSearchTerm = window.location.search.split("=")[1]
      if (urlSearchTerm) {
        setSearchTerm(urlSearchTerm)
        // navigate to /search
        navigate(`/search`)
      }
    }
  }, [])

  let client = new Typesense.Client({
    nodes: [
      {
        host: "xv6b3dtg9fjq05rzp-1.a1.typesense.net", // For Typesense Cloud use xxx.a1.typesense.net
        port: "443", // For Typesense Cloud use 443
        protocol: "https", // For Typesense Cloud use https
      },
    ],
    apiKey: `${process.env.GATSBY_TYPESENSE_SEARCH}`,
    connectionTimeoutSeconds: 2,
  })

  return (
    <SearchStateContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        results,
        loading,
        setResults,
        setSearch: () => {
          setLoading(true)
          client
            .collections("pages_v1")
            .documents()
            .search({
              q: searchTerm,
              query_by: "title, description",
            })
            .then(function (response) {
              console.log(response.hits)
              setResults(response.hits)
              setLoading(false)
            })
        },
      }}
    >
      {children}
    </SearchStateContext.Provider>
  )
}

export default SearchStateContext

export { SearchContextProvider }
