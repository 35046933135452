import React, { useState, useEffect, createContext } from "react"

const FormStateContext = createContext()

const FormContextProvider = ({ children }) => {
  const [formConfirmationMessage, setFormConfirmationMessage] = useState(
    "Thank you for your submission. We will review your message and respond as soon as we can."
  )

  return (
    <FormStateContext.Provider
      value={{
        formConfirmationMessage,
        setFormConfirmationMessage,
      }}
    >
      {children}
    </FormStateContext.Provider>
  )
}

export default FormStateContext

export { FormContextProvider }
