import React from "react"
import { SearchContextProvider } from "./src/context/SearchContext"
import { FormContextProvider } from "./src/context/FormContext"

const wrapRootElement = ({ element }) => (
  <SearchContextProvider>
    <FormContextProvider>{element}</FormContextProvider>
  </SearchContextProvider>
)

export { wrapRootElement }

// export const wrapRootElement = SearchContextProvider

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {
    addScript("https://js.hsforms.net/forms/v2.js?pre=1")
  }
}
